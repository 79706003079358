.img-icon-w {
  width: 3rem;
}

.link-pending {
  cursor: pointer;
}

/* Pagination */
.pagination {
  display: flex;
  flex-direction: row;
}

.current-page {
  font-size: 1.5rem;
  vertical-align: middle;
}

ul.pagination {
  margin-top: 0;
  margin-bottom: 0;
  /*     box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}

ul.pagination li.page-item.active a.page-link {
  color: #445565 !important;
  background-color: #e3e7eb !important;
  border-color: #ced4da !important;
}

ul.pagination a.page-link {
  padding: 0.75rem 1rem;
  min-width: 3.5rem;
  text-align: center;
  box-shadow: none !important;
  border-color: #ced4da !important;
  color: #6b88a4;
  font-weight: 900;
  font-size: 1rem;
}

ul.pagination a.page-link:hover {
  background-color: #f4f4f4;
}

.Button-cancelar-enviar:hover {
  outline: none !important;
  color: #fff;
  color: #ffff !important;
  text-transform: capitalize;
  background-color: #f56565 !important;
}

.Button-cancelar-enviar {
  outline: none !important;
  background-color: #ffff !important;
  border-color: #ffffff !important;
  border-radius: 50px !important;
  color: #f56565 !important;
  text-transform: capitalize !important;
  color: rgba(245, 101, 101, var(--text-opacity));
  transition: all 0.3s ease 0s;
}

.MuiCircularProgress-svg {
  color: white;
}

.button-outline-off {
  outline: none !important;
}

.apexcharts-legend {
  width: auto !important;
  left: -14% !important;
}

.orange {
  background-color: #ff9800;
}
.orange-border {
  border-color: #ff9800;
}
.orange-text {
  color: #ff9800;
}

.Color-red {
  background-color: #ff0000;
}

.text-green {
  color: #1cce27;
}

.centrar-contenido {
  display: flex;
  justify-content: center !important;
}

button-outline-off:focus {
  outline: none !important;
}

.button-outline-off {
  outline: none !important;
}
Button {
  outline: none !important;
}

.btn-retiros {
  background: #8a8a8e;
  border-color: #8a8a8e;
  cursor: auto;
}

.color-warning {
  margin-top: 10px;
  color: #ff0000;
}

.input-border-green:after {
  border: 0px !important;
}

.input-border-green:before {
  border-color: transparent !important;
}

.input-borders {
  border-radius: 3px !important;
  border-width: 1.75px !important;
  padding-left: 10px !important;
  padding-right: 5px !important;
}

.MuiInputBase-input {
  width: 290px !important;
}

.MuiInputBase-input:focus {
  background: transparent !important;
}

.modal-nombre {
  display: flex !important;
  justify-content: space-between !important;
}

.margin-10px {
  margin-bottom: 10px;
}

.margin-top-10px {
  margin-top: 10px;
}

.lower {
  text-transform: lowercase !important;
}

.lower::first-letter {
  text-transform: uppercase;
}

.resaltado-creados {
  border-radius: 3px;
  background: #ecd64b;
}

.resaltado-proceso {
  border-radius: 3px;
  width: 105%;
  justify-content: center;
  background: #ff9800;
}

.bloqued-cursor {
  cursor: not-allowed !important;
}

.copy-cursor {
  cursor: copy !important;
}

.copiado {
  position: absolute;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  background: #ffffff;
  right: 7%;
  bottom: 35%;
  width: 70px;
  height: 40%;
  box-shadow: 0px 4px 7px rgb(0 0 0 / 30%);
  animation: showcopied 2.2s linear;
}

@keyframes showcopied {
  0% {
    opacity: 0;
    box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
    color: #ffffff;
  }

  45% {
    opacity: 1;
    color: gray;
    box-shadow: 0px 4px 7px rgb(0 0 0 / 30%);
  }

  90% {
    box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
  }

  100% {
    color: #ffffff;
    opacity: 0;
  }
}

.copiado-modal {
  position: absolute;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  background: #ffffff;
  right: 31%;
  bottom: 109%;
  width: 150px;
  height: 33px;
  box-shadow: 0px 6px 8px rgb(0 0 0 / 60%);
  animation: showcopied 2.6s linear;
}
.div-colaboradores {
  display: grid;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}
.div-form {
  width: 60% !important;
}
@media (min-width: 640px) {
  .div-colaboradores {
    display: flex;
    width: 100% !important;
  }
}
@media (max-width: 1025px) {
  .div-form {
    width: 100% !important;
  }
}
@media (max-width: 1025px) {
  .div-colaboradores {
    display: block;
    width: 100% !important;
  }
}
.animacionTabla {
  transform: translateX(0px);
  transition: 0.3s linear;
}
.animacionTabla:hover {
  transform: translateX(0);
}
.grid-edit {
  display: grid;
  grid-template-columns: 3fr 12fr;
  gap: 10px;
}
@media (max-width: 1025px) {
  .grid-edit {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
animacion-colaborador {
  opacity: 0;
  animation: showcolaborator 2s linear;
}
@keyframes showcolaborator {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
input {
  outline: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #201f26 inset !important;
  -webkit-text-fill-color: #ffffff !important; /* Color del texto */
}

.links {
  color: #63b3ed !important;
  text-decoration: none !important;
}
.notlinks {
  text-decoration: none !important;
}
.modal-recarga {
  display: flex;
  position: absolute;
  width: 84% !important;
  height: 93% !important;
  background: rgba(47, 46, 44, 0.5);
  backdrop-filter: blur(5px) !important;
  z-index: 999999 !important;
  justify-content: center;
  align-items: center;
}
select,
option {
  font-family: "Montreal Regular" !important;
}

.modal-documents {
  filter: blur(3px) opacity(30%);
  -webkit-filter: blur(3px) opacity(30%);
  -moz-filter: blur(3px) opacity(30%);
}
.buttonAzul:hover {
  background: #2e86c1;
  color: #fff !important;
}
.colorAzulEzy {
  color: #2e86c1 !important;
}
textarea {
  outline: none;
}
.select-nav {
  font-size: 14px;
  margin-left: 20px;
  border-radius: 5px;
  outline: none;
  background: white;
  height: 32px;
  padding: 0px 32px 0px 12px;
}
.css-1nmdiq5-menu {
  position: absolute !important;
}
.hover-bac:hover {
  background: white;
  color: black;
}
.gafas {
  width: 150px;
  height: 50px;
  border: 2px solid black;
  border-radius: 10px;
  position: relative;
}

.gafas:before {
  content: "";
  display: block;
  position: absolute;
  top: 20px;
  left: -20px;
  width: 20px;
  height: 2px;
  background-color: black;
}

.gafas:after {
  content: "";
  display: block;
  position: absolute;
  top: 20px;
  right: -20px;
  width: 20px;
  height: 2px;
  background-color: black;
}
.custom-select {
  position: relative;
  display: inline-block;
}

.custom-select::after {
  position: absolute;
  top: 50%;
  right: 15px; /* Ajusta el valor según tus necesidades */
  transform: translateY(-10%);
  pointer-events: none;
}

.custom-select select {
  background-position: right center;
  background-repeat: no-repeat;

  padding-right: 25px; /* Ajusta el valor según tus necesidades */
  /* Agrega otros estilos personalizados según tus preferencias */
}
.grecaptcha-badge {
  display: none !important;
}
.toggle-container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.toggle-input {
  display: none;
}

.toggle-slider {
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 10px;
  position: relative;
  transition: background-color 0.3s;
}

.toggle-slider:before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 50%;
  top: 1px;
  left: 1px;
  transition: left 0.3s;
}

.toggle-input:checked + .toggle-slider {
  background-color: #4caf50;
}

.toggle-input:checked + .toggle-slider:before {
  left: 20px;
}

p {
  margin-left: 10px;
}

.backgroundColurs {
  background: #141319 !important;
}

.backgroundColursLayouts {
  background: #201f26 !important;
}
.colursMainWhite {
  color: white !important;
}

.colursPlasholder::placeholder {
  color: #8a8a8e;
}
.colursPlasholder {
  color: #8a8a8e;
}
.backgroundGray {
  background: #8a8a8e;
}
.colursBorder {
  border-color: #5e5e63 !important;
}
.backgroundButton {
  background: white;
}
.backgroundLayout {
  background: #201f26;
}

#root {
  background: #141319 !important;
}
html,
body,
#root,
.App {
  background-color: #141319;
}
#pagination-previous-page,
#pagination-first-page,
#pagination-next-page,
#pagination-last-page {
  color: #fff !important; /* Cambia esto al color que prefieras */
}

#pagination-previous-page svg,
#pagination-first-page svg,
#pagination-next-page svg,
#pagination-last-page svg {
  fill: #fff !important; /* Cambia el color de los íconos SVG */
}
.sidebarColursOFF {
  background: #201f26 !important;
  color: #8a8a8e;
}
.sidebarColursOFF:hover {
  color: white;
}

.sidebarColursON {
  background: #141319;
  color: white;
}

.ButtonCancelarColurs {
  background: #2a2930 !important;
  color: #ff928b !important;
  border-radius: 30px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.ButtonCancelarColurs:hover {
  background: white !important;
  color: #ff928b !important;
  border-radius: 30px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.filterbuttoncolurs {
  background: #201f26 !important;
  color: white !important;
}
.filterButtonColurs:hover {
  background: white !important;
  color: black !important;
}
